<template>
    <div id="mainContent" class="mt-8 sm:w-1/2 m-auto mb-8">
      <h1 class="text-2xl sm:text-3xl font-extrabold">Mental Health Awareness</h1>

      <button class="py-2 px-3 bg-gray-300 hover:bg-gray-200 rounded-xl donatebutton mt-4" onclick="location.href='https://gofund.me/4f41dd1f'">Donate</button>
      <h1 class="text-xl mt-4">We're raising money for <a class="text-orange-500 font-bold " href="https://chaptermentalhealth.org/">Chapter</a>, a local mental health charity who support people who suffer from mental illnesses.<br>They offer one to one services, a listening service, workshops and group activities.</h1>
      <div class="mx-5 mt-3">
        <h1 class="text-2xl sm:text-3xl font-extrabold mt-8">Why is mental health important?</h1>
        <p class="text-xl mt-2">Mental health is extremely important because it affects <strong>1</strong> in <strong>4</strong> people. Mental health charities provide advice and resources to those who need it most, whether it be through counselling, campaigning or helping individuals assess their needs and options.</p>
      </div>

      <div class="mx-5 mt-3">
        <h1 class="text-2xl sm:text-3xl font-extrabold mt-8">Who can I talk to?</h1>
        <p class="text-xl mt-2">Speaking up is important, that is why there are hotlines you can talk to.</p>

        <div class="w-full m-auto mt-3">

          <div class="flex justify-center">
            <div class="flex items-center">
              <img src="https://cdn.myprojects.lol/Background%20-%202022-07-19T210808.025.png" class="rounded-2xl h-28">
              <div class="text-left ml-2">
                <div>
                  <p class="font-bold">Papyrus Mental Health</p>
                  <p>Open 9am-12am daily</p>
                  <p><a href="tel:0800 068 4141" class="underline">0800 068 4141 <i class="fa-solid fa-phone"></i></a></p>
                </div>
              </div>
            </div>
          </div>

          <br>

          <div class="flex justify-center">
            <div class="flex items-center">
              <img src="https://cdn.myprojects.lol/Background%20-%202022-07-19T010051.248.png" class="rounded-2xl h-28">
              <div class="text-left ml-2">
                <div>
                  <p class="font-bold">Samaritans Hotline</p>
                  <p>24/7</p>
                  <p><a href="tel:116 123" class="underline">116 123 <i class="fa-solid fa-phone"></i></a></p>
                </div>
              </div>
            </div>
          </div>

          <br>

          <div class="flex justify-center">
            <div class="flex items-center">
              <img src="https://cdn.myprojects.lol/Background%20-%202022-07-19T010954.558.png" class="rounded-2xl h-28">
              <div class="text-left ml-2">
                <div>
                  <p class="font-bold">Shout 85258 (SMS)</p>
                  <p>24/7 SMS hotline</p>
                  <p><a href="sms: 85258?&body=SHOUT" class="underline">85258 (SMS)<i class="fa-solid fa-phone"></i></a></p>
                </div>
              </div>
            </div>
          </div>
          
          <br>

          <div class="flex justify-center">
            <div class="flex items-center">
              <img src="https://cdn.myprojects.lol/Background%20-%202022-07-19T211448.065.png" class="rounded-2xl h-28">
              <div class="text-left ml-2">
                <div>
                  <p class="font-bold">Kooth</p>
                  <p>Mental Health Website</p>
                  <p><a href="https://kooth.com" class="underline">kooth.com<i class="fas fa-link"></i></a></p>
                </div>
              </div>
            </div>
          </div>

          <br>

          <div class="flex justify-center">
            <div class="flex items-center">
              <img src="https://cdn.myprojects.lol/Background%20-%202022-07-19T010837.663.png" class="rounded-2xl h-28">
              <div class="text-left ml-2">
                <div>
                  <p class="font-bold">CALM Hotline</p>
                  <p>Open 5pm-12am daily</p>
                  <p><a href="tel:0800585858" class="underline">0800585858<i class="fa-solid fa-phone"></i></a></p>
                </div>
              </div>
            </div>
          </div>


      </div>
    </div>
  </div>
    
</template>

<script>
export default {
  name: 'HomePage',
  components: {
  },
  methods: {
    comingSoon() {
      // Use sweetalert2
      this.$swal('Donation page coming soon');
    },
  }
}
</script>
